import React from 'react';

import './index.scss';

const TileGrid = props => {

	const margin = props.margin && props.margin !== 'none' ? ` margin-${props.margin}` : '';
	const width = props.tileWidth;

	return (
		<div id={props.id} className={`tile-grid${margin}`}>
			<ul>
				{props.tile.map((tile, i) => {
					return (
						<li key={i} style={{ width: width, paddingBottom: width }}>
							<div className="tile-wrap">
								<img src={tile.image.url} alt={tile.image.alt} />
								 {tile.label.length > 0 &&
							        <label>{tile.label}</label>
							      }
							</div>
						</li>
					)
				})}
			</ul>
		</div>
	)
}

export default TileGrid;